<template lang="">
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Cooperantes
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color=""
              dark
              class="mb-2 float-right light-blue-502"
              @click="modalNuevo"
            >
              <v-icon>mdi-plus</v-icon>
              Registrar cooperante
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="cooperantes"
          :search="filtro"
          :loading="tableCooperantesLoading"
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="País, agencia, fuente multilateral"
                    maxlength="200"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.fuente }}</td>
              <td>{{ item.agencia ? item.agencia : "N/A" }}</td>
              <td>{{ item.nombreTipoFuente }}</td>
              <td>{{ item.maximaAutoridad }}</td>
              <!-- <td>
                  {{
                    item.nombreMaximaAutoridad != null
                      ? item.nombreMaximaAutoridad
                      : ""
                  }}
                </td>-->
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id, item.cooperante)"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>

                <v-btn
                  v-if="item.estadosId != 2"
                  class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="inhabilitarItem(item.id, item.agencia, item.fuente)"
                >
                  <v-icon left>mdi-pencil</v-icon> Inhabilitar
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 2"
                  class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="habilitarItem(item.id, item.agencia, item.fuente)"
                >
                  <v-icon left>mdi-pencil</v-icon> Habilitar
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1280"
      transition="scroll-y-transition"
      persistent
    >
      <v-card tile>
        <v-card-title>
          {{
            accion === 2
              ? `Actualizar | ${nombreCooperante}`
              : `Registrar cooperante`
          }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text>
          <v-form
            ref="form"
            v-on:submit.prevent="cargarArchivo"
            v-model="validForm"
            class="pt-4 pl-2 pr-2"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  v-model="tipoFuenteCooperanteSeleccionada"
                  :items="tiposFuenteCooperante"
                  :loading="false"
                  dense
                  filled
                  class="required"
                  label="Tipo fuente cooperante"
                  item-text="nombreTipoFuente"
                  item-value="id"
                  return-object
                  :rules="[selectRequired('nombre del tipo fuente cooperante')]"
                  :no-data-text="
                    tiposFuenteCooperante != null
                      ? 'Selecciona un tipo de fuente cooperante'
                      : 'No se han encontrado tipos de fuente cooperante'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="tipoFuenteCooperanteSeleccionada.id === 1">
              <v-col
                cols="12"
                md="6"
                sm="6"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id === 1"
              >
                <v-select
                  v-model="fuenteBilateralSeleccionada"
                  :items="fuentesBilaterales"
                  :loading="false"
                  :required="tipoFuenteCooperanteSeleccionada.id == 1"
                  dense
                  :class="
                    tipoFuenteCooperanteSeleccionada.id == 1 ? 'required' : ''
                  "
                  filled
                  label="País fuente bilateral"
                  item-text="pais"
                  item-value="id"
                  return-object
                  :rules="[
                    tipoFuenteCooperanteSeleccionada.id === 1
                      ? selectRequired('país bilateral')
                      : true
                  ]"
                  :no-data-text="
                    fuentesBilaterales != null
                      ? 'Selecciona un país'
                      : 'No se han encontrado países'
                  "
                  @change="obtenerAgenciasPorFuenteBilateral()"
                  menu-props="auto"
                ></v-select>
              </v-col>

              <!-- inicio:: agencias -->
              <v-col
                cols="12"
                md="6"
                sm="6"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id === 1"
              >
                <v-select
                  v-model="agenciaSeleccionada"
                  :items="agencias"
                  :clearable="true"
                  :loading="ddAgenciasLoading"
                  dense
                  filled
                  label="Seleccione agencia"
                  item-text="nombreAgencia"
                  item-value="id"
                  return-object
                  :no-data-text="
                    agencias.length > 0
                      ? 'Selecciona una agencia'
                      : 'No se han encontrado agencias'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>
              <!-- fin:: agencias -->
            </v-row>

            <v-row v-if="tipoFuenteCooperanteSeleccionada.id === 2">
              <v-col
                cols="12"
                md="6"
                sm="12"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id === 2"
              >
                <v-select
                  v-model="fuenteMultilateralSeleccionada"
                  :items="fuentesMultilateral"
                  :loading="false"
                  dense
                  :class="
                    tipoFuenteCooperanteSeleccionada.id == 2 ? 'required' : ''
                  "
                  filled
                  label="Seleccione fuente multilateral"
                  item-text="nombreFuenteMultilateral"
                  item-value="id"
                  return-object
                  @change="
                    obtenerAgenciasMultilaterales(
                      fuenteMultilateralSeleccionada.id
                    )
                  "
                  :rules="[
                    tipoFuenteCooperanteSeleccionada.id === 2
                      ? selectRequired('fuente multilateral')
                      : true
                  ]"
                  :no-data-text="
                    fuentesMultilateral != null
                      ? 'Seleccione una fuente multilateral'
                      : 'No se han encontrado fuentes multilaterales'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>

              <!-- inicio:: agencias -->
              <v-col
                cols="12"
                md="6"
                sm="12"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id === 2"
              >
                <v-select
                  :clearable="true"
                  v-model="agenciaMultilateralSeleccionada"
                  :items="agenciasMultilaterales"
                  :loading="ddAgenciasMultiLoading"
                  dense
                  filled
                  label="Seleccione agencia"
                  item-text="agencia"
                  item-value="id"
                  return-object
                  :no-data-text="
                    agenciasMultilaterales.length > 0
                      ? 'Selecciona una agencia'
                      : 'No se han encontrado agencias'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>
              <!-- fin:: agencias multi -->
            </v-row>

            <v-row>
              <v-col cols="12" md="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.maximaAutoridad"
                  label="Nombre de la máxima autoridad"
                  :rules="[
                    required('Nombre de la máxima autoridad'),
                    minLength('Nombre de la máxima autoridad', 5),
                    maxLength('Nombre de la máxima autoridad', 150)
                  ]"
                  maxlength="150"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" class="pt-0 pb-0">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.antecedentes"
                  label="Antecedentes"
                  rows="3"
                  :rules="[
                    required('Antecedentes'),
                    minLength('Antecedentes', 5),
                    maxLength('Antecedentes', 2000)
                  ]"
                  maxlength="2000"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.correo"
                  label="Correo electrónico"
                  :rules="[
                    required('correo electrónico'),
                    minLength('correo electrónico', 5),
                    maxLength('correo electrónico', 150),
                    validEmail('correo electrónico')
                  ]"
                  maxlength="150"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.sitioWeb"
                  label="Sitio web"
                  :rules="[
                    required('sitio web'),
                    minLength('sitio web', 5),
                    maxLength('sitio web', 250)
                  ]"
                  maxlength="250"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.telefono"
                  label="Número de telefono"
                  :rules="[
                    required('número de teléfono'),
                    minLength('número de teléfono', 8),
                    maxLength('número de teléfono', 12)
                  ]"
                  maxlength="12"
                  v-mask="'############'"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  autocomplete="off"
                  class="required"
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="datosItem.direccion"
                  label="Dirección"
                  :rules="[
                    required('dirección'),
                    minLength('dirección', 8),
                    maxLength('dirección', 150)
                  ]"
                  maxlength="150"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <template>
                  <v-file-input
                    id="respaldoFile"
                    dense
                    filled
                    placeholder="Seleccionar archivo .jgp o png"
                    label="Fotografía de la máxima autoridad"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="image/png,image/jpeg"
                    @change="onFileChange"
                    :rules="[fileRequired('Fotografía')]"
                    :show-size="1000"
                    ref="respaldoFile"
                    class="required"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                      text-overline
                                      grey--text
                                      text--darken-3
                                      mx-2
                                  "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div id="preview">
                  <img v-if="imageUrl" :src="imageUrl" />
                </div>
                <!--  <div id="preview" v-if="accion === 2 && imageUrlServer">
                  <img v-if="imageUrlServer" :src="imageUrlServer" />
                </div>
                <div id="preview" v-else>
                  <img v-if="imageUrl" :src="imageUrl" />
                </div> -->
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" class="pb-0">
                <v-spacer></v-spacer>
                <!--:disabled="!validDocForm" type="submit"-->
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right ml-2"
                  type="submit"
                  :elevation="0"
                  :disabled="!validForm"
                  :loading="btnRegistroLoading"
                >
                  {{
                    accion === 1
                      ? `Registrar cooperante`
                      : `Actualizar cooperante`
                  }}
                </v-btn>

                <v-btn
                  color="grey lighten-5"
                  elevation="0"
                  class="mb-2 float-right grey lighten-5"
                  @click="
                    dialogRegistro = false;
                    resetForm();
                  "
                  :disabled="btnRegistroLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <!-- SECTORES PRIORITARIOS BENEFICIADOS -->
          <v-card outlined class="mt-6" v-if="accion === 2">
            <v-card-title>
              <v-row class="col-md-12">
                <v-col cols="12" sm="12" md="6">
                  Sectores prioritarios
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formSector"
                v-on:submit.prevent="registrarSector"
                v-model="validFormSectores"
                class="pt-4 pl-2 pr-2"
              >
                <v-row class="mt-4">
                  <v-col cols="12" md="8" sm="12" class="pt-0 pb-0">
                    <v-select
                      v-model="datosSector.sectoresId"
                      :items="sectoresBeneficiados"
                      :loading="false"
                      dense
                      class="required"
                      filled
                      label="Seleccione el sector principal que beneficia"
                      item-text="sector"
                      :rules="[
                        selectRequired('sector principal que beneficia')
                      ]"
                      item-value="id"
                      :no-data-text="
                        sectoresBeneficiados != null
                          ? 'Seleccione un sector beneficiado'
                          : 'No se han encontrado sectores beneficiado'
                      "
                      menu-props="auto"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4" sm="12" class="pb-0">
                    <!-- <v-spacer></v-spacer> -->
                    <!--:disabled="!validDocForm" type="submit"-->
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right ml-2"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormSectores"
                      :loading="btnRegistroSectoresLoading"
                    >
                      Registrar sector prioritario
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                  <v-subheader class="text-h6 black--text">
                    Sectores registrados del cooperante
                  </v-subheader>
                </v-col>

                <!--inicio:: tabla sectores prioritarios-->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    dense
                    class="elevation-1"
                    :headers="headersSectores"
                    :items="sectoresBeneficiadosRegistrados"
                    :loading="tableLoadingSectores"
                    :header-props="{
                      sortByText: 'Ordenar por'
                    }"
                    no-data-text="No se encontraron registros"
                    loading-text="Cargando..."
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}'
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <!-- <td>{{ item.id }}</td> -->
                        <td>{{ item.sector }}</td>
                        <td>
                          <v-btn
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            :disabled="btnEliminarSectorDisabled"
                            color="blue-grey lighten-5"
                            @click="eliminarSector(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No se encontraron
                        registros
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tablasectores prioritarios-->
              </v-row>
            </v-card-text>
          </v-card>
          <!--FIN:: SECOTRES PRIORITARIOS -->

          <!-- INICIO:: MODALIDADES COOPERACION -->
          <v-card outlined class="mt-4" v-if="accion === 2">
            <v-card-title>
              <v-row class="col-md-12">
                <v-col cols="12" sm="12" md="12">
                  Modalidades cooperación
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formSector"
                v-on:submit.prevent="registrarModalidad"
                v-model="validFormModalidades"
                class="pt-4 pl-2 pr-2"
              >
                <v-row class="mt-4">
                  <v-col cols="12" md="8" sm="12" class="pt-0 pb-0">
                    <v-select
                      v-model="datosModalidad.modalidadCooperacionCNSCId"
                      :items="modalidadesCooperacion"
                      :loading="false"
                      dense
                      class="required"
                      filled
                      label="Seleccione la modalidad de cooperación"
                      item-text="modalidad"
                      :rules="[selectRequired('modalidad de cooperación')]"
                      item-value="id"
                      :no-data-text="
                        modalidadesCooperacion != null
                          ? 'Seleccione modalidad de cooperación'
                          : 'No se han encontrado modalidades de cooperación'
                      "
                      menu-props="auto"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4" sm="12" class="pb-0">
                    <v-spacer></v-spacer>
                    <!--:disabled="!validDocForm" type="submit"-->
                    <v-btn
                      color="light-blue-502"
                      class="white--text mb-2 float-right ml-2"
                      type="submit"
                      :elevation="0"
                      :disabled="!validFormModalidades"
                      :loading="btnRegistroModalidadesLoading"
                    >
                      Registrar modalidad
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="accion === 2">
                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                  <v-subheader class="text-h6 black--text">
                    Modalidades de cooperación registradas del cooperante
                  </v-subheader>
                </v-col>

                <!--inicio:: tabla sectores prioritarios-->
                <v-col cols="12" md="12" sm="12" class="pt-4">
                  <v-data-table
                    dense
                    class="elevation-1"
                    :headers="headersModalidades"
                    :items="modalidadesRegistradas"
                    :loading="tableLoadingModalidades"
                    :header-props="{
                      sortByText: 'Ordenar por'
                    }"
                    no-data-text="No se encontraron registros"
                    loading-text="Cargando..."
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      'items-per-page-text': 'Registros por página',
                      pageText: '{0}-{1} de {2}'
                    }"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <!-- <td>{{ item.id }}</td> -->
                        <td>{{ item.modalidad }}</td>
                        <td>
                          <v-btn
                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                            small
                            depressed
                            :disabled="btnEliminarModalidadDisabled"
                            color="blue-grey lighten-5"
                            @click="eliminarModalidad(item.id)"
                          >
                            <v-icon left>mdi-delete</v-icon> Eliminar
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <p class="text-h7">
                        <v-icon left>mdi-alert</v-icon> No existe información
                        registrada de proyección de contrapartida
                      </p>
                    </template>
                  </v-data-table>
                </v-col>
                <!--fin:: tablasectores prioritarios-->
              </v-row>
            </v-card-text>
          </v-card>
          <!-- FIN:: MODALIDADES COOPERACION -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end:: modal registro-->

    <!--begin:: dialog confirmar inhabilitacion/habilitacion -->
    <v-dialog
      v-model="dialogConfirmacion"
      width="600"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{
            tipoConfirmacion === 1
              ? `Inhabilitar cooperante`
              : `Habilitar cooperante`
          }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnConfirmacionLoading"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <p class="text-subtitle-1 mb-1 pb-0">
                {{
                  tipoConfirmacion === 1
                    ? `Está seguro de inhabilitar el cooperante ${nombreCooperante}`
                    : `Está seguro de habilitar nuevamente el cooperante ${nombreCooperante}`
                }}?
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogConfirmacion = false"
            :disabled="btnConfirmacionLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="actualizarEstadoCooperante"
            :loading="btnConfirmacionLoading"
          >
            Sí, {{ tipoConfirmacion === 1 ? `inhabilitar` : `hablitar` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
//import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  OBTENER_ITEMS_CATALOGO,
  OBTENER_SUBITEMS_CATALOGO
} from "@/core/services/store/catalogos/catalogointerno.module";

import {
  OBTENER_COOPERANTES_CNSC,
  REGISTRAR_COOPERANTE_CNSC,
  OBTENER_COOPERANTE_CNSC,
  ACTUALIZAR_COOPERANTE_CNSC
} from "@/core/services/store/cooperantes/cooperante.module";

import {
  OBTENER_SECTORES_COOPERANTE,
  REGISTRAR_SECTOR_COOPERANTE,
  ELIMINAR_SECTOR_COOPERANTE
} from "@/core/services/store/cooperantes/sectorescooperante/sectorcooperante.module";

import {
  OBTENER_MODALIDADES_COOPERACION_COOPERANTE,
  REGISTRAR_MODALIDAD_COOPERACION_COOPERANTE,
  ELIMINAR_MODALIDAD_COOPERACION_COOPERANTE
} from "@/core/services/store/cooperantes/modalidadescooperacioncooperante/modalidadcooperacioncooperante.module";

import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";

import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
export default {
  name: "Admincooperantes",
  components: {
    DialogLoader,
    SnackAlert
  },
  data() {
    return {
      btnRegistroLoading: false,
      validForm: false,
      validFormSectores: false,
      btnRegistroSectoresLoading: false,
      validFormModalidades: false,
      btnRegistroModalidadesLoading: false,
      btnEliminarSectorDisabled: false,
      btnEliminarModalidadDisabled: false,
      cooperantes: [],
      tableCooperantesLoading: false,
      filtro: "",
      accion: 1,
      sectoresBeneficiados: [],
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      nombreCooperante: "",
      dialogRegistro: false,
      tiposSocioImplementador: [],
      ddTiposSocioImplementador: false,
      tipoFuenteCooperanteSeleccionada: [],
      tiposFuenteCooperante: [],
      fuenteBilateralSeleccionada: {},
      fuentesBilaterales: [],
      agenciaSeleccionada: {},
      agencias: [],
      ddAgenciasLoading: false,
      fuenteMultilateralSeleccionada: {},
      fuentesMultilateral: [],
      modalidadesCooperacion: [],
      agenciasMultilaterales: [],
      agenciaMultilateralSeleccionada: {},
      ddAgenciasMultiLoading: false,
      datosItem: {
        antecedentes: "",
        maximaAutoridad: "",
        direccion: "",
        telefono: "",
        sitioWeb: "",
        correo: "",
        tiposFuenteCooperanteId: 0,
        fuentesBilateralesId: 0,
        agenciasId: 0,
        fuentesMultilateralesId: 0,
        agenciaMultilateralId: 0,
        sectoresBeneficiadosId: 0,
        modalidadCooperacionCNSCId: 0,
        estrategiaCNSCId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      datosSector: {
        cooperantesCNSCId: 0,
        sectoresId: 0,
        cooperantesId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      sectoresBeneficiadosRegistrados: [],
      tableLoadingSectores: false,
      modalidadesRegistradas: [],
      tableLoadingModalidades: false,
      datosModalidad: {
        modalidadCooperacionCNSCId: 0,
        cooperantesId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      estado: "",

      estados: [
        { text: "Todos", value: null },
        { value: 1, text: "Activo" },
        { value: 2, text: "Inactivo" }
      ],
      cooperanteId: 0,
      tipoConfirmacion: 0,
      dialogConfirmacion: false,
      btnConfirmacionLoading: false,
      imageUrl: null,
      imageUrlServer: null,
      archivo: [],
      actualizaImagen: false,
      ...validations
    };
  },

  methods: {
    onFileChange(e) {
      if (this.archivo && this.archivo.size) {
        this.archivo = [];
        //document.querySelector("#respaldoFile").value = "";
        //this.$refs.respaldoFile.reset();
        //this.$refs['respaldoFile'][0].reset();
      }

      this.imageUrl = null;
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "image/png" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 1) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (1Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }

        this.archivo = e;
        this.actualizaImagen = true;
        this.imageUrl = URL.createObjectURL(this.archivo);
      }
    },

    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estado.text;
    },

    async obtenerItems() {
      return 0;
    },

    //Obtener los tipos de fuente cooperante
    async obtenerTiposFuenteCooperante() {
      this.tiposFuenteCooperante = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposFuenteCooperante/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.tiposFuenteCooperante = res.data;
          }
        })
        .catch(() => {
          this.tiposFuenteCooperante = [];
        });
    },

    async obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuentesBilaterales/all/1/0"
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
          }
        })
        .catch(() => {
          this.fuentesBilaterales = [];
        });
    },

    //Obtener las agencias por fuente bilateral
    async obtenerAgenciasPorFuenteBilateral() {
      this.agencias = [];
      this.ddAgenciasLoading = true;
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {
          endpoint: "Agencias/all/1",
          id: this.fuenteBilateralSeleccionada.id
        })
        .then(res => {
          if (res.status === 200) {
            this.agencias = res.data;
          }
          this.ddAgenciasLoading = false;
        })
        .catch(() => {
          this.ddAgenciasLoading = false;
          this.agencias = [];
        });
    },

    async obtenerFuentesMultilateral() {
      this.fuentesMultilateral = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuenteMultiLateral/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilateral = res.data;
          }
        })
        .catch(() => {
          this.fuentesMultilateral = [];
        });
    },

    // Obtener agencias Multilaterales
    async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
      this.agenciasMultilaterales = [];
      this.ddAgenciasMultiLoading = true;

      await this.$store
        .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {
          estadoId: 1,
          fuentesMultilateralesId: fuenteMultilateralId
        })
        .then(res => {
          if (res.status === 200) {
            this.agenciasMultilaterales = res.data;
          }
          this.ddAgenciasMultiLoading = false;
        })
        .catch(() => {
          //console.logerror
          this.ddAgenciasMultiLoading = false;
        });
    },

    //Sectores beneficiados
    async obtenerSectoresBeneficiados() {
      this.sectoresBeneficiados = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Sectores/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.sectoresBeneficiados = res.data;
          }
        })
        .catch(() => {
          this.sectoresBeneficiados = [];
        });
    },

    //Modalidades de cooperacion
    async obtenerModalidadesCooperacion() {
      this.modalidadesCooperacion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "ModalidadCooperacionCNSC/all/1"
        })
        .then(res => {
          if (res.status === 200) {
            this.modalidadesCooperacion = res.data;
          }
        })
        .catch(() => {
          this.modalidadesCooperacion = [];
        });
    },

    modalNuevo() {
      this.accion = 1;
      this.resetForm();
      this.dialogRegistro = true;
    },

    resetForm() {
      this.nombreCooperante = "";
      this.agenciaSeleccionada = {};
      this.fuenteBilateralSeleccionada = {};
      this.fuenteMultilateralSeleccionada = {};
      this.agenciaMultilateralSeleccionada = {};
      this.datosItem = {
        antecedentes: "",
        maximaAutoridad: "",
        direccion: "",
        telefono: "",
        sitioWeb: "",
        correo: "",
        tiposFuenteCooperanteId: 0,
        fuentesBilateralesId: 0,
        agenciasId: 0,
        fuentesMultilateralesId: 0,
        agenciaMultilateralId: 0,
        sectoresBeneficiadosId: 0,
        modalidadCooperacionCNSCId: 0,
        estrategiaCNSCId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      };
      this.imageUrl = null;

      //this.archivo = [];
      this.actualizaImagen = false;
      if (this.archivo && this.archivo.size) {
        this.archivo = [];
        document.querySelector("#respaldoFile").value = "";
        this.$refs.respaldoFile.reset();
        //this.$refs['respaldoFile'][0].reset();
      }
    },

    async obtenercooperantes() {
      this.tableCooperantesLoading = true;
      this.cooperantes = [];
      await this.$store
        .dispatch(OBTENER_COOPERANTES_CNSC, {
          estadoId: 1,
          tipoFuenteCooperanteId: 0
        })
        .then(res => {
          if (res.status === 200) {
            this.cooperantes = res.data;
          }
          this.tableCooperantesLoading = false;
        })
        .catch(() => {
          this.cooperantes = [];
          this.tableCooperantesLoading = false;
        });
    },

    //Obtener los sectores prioritarios del cooperante
    async obtenerSectoresPrioritariosBeneficiados(cooperanteId) {
      this.tableLoadingSectores = true;
      this.sectoresBeneficiadosRegistrados = [];
      await this.$store
        .dispatch(OBTENER_SECTORES_COOPERANTE, {
          estadoId: 1,
          cooperanteId: cooperanteId
        })
        .then(res => {
          if (res.status === 200) {
            this.sectoresBeneficiadosRegistrados = res.data;
          }
          this.tableLoadingSectores = false;
        })
        .catch(() => {
          this.sectoresBeneficiadosRegistrados = [];
          this.tableLoadingSectores = false;
        });
    },

    //Obtener las modalidades de cooperacion del cooperante
    async obtenerModalidadesCooperacionCooperantes(cooperanteId) {
      this.tableLoadingModalidades = true;
      this.modalidadesRegistradas = [];
      await this.$store
        .dispatch(OBTENER_MODALIDADES_COOPERACION_COOPERANTE, {
          estadoId: 1,
          cooperanteId: cooperanteId
        })
        .then(res => {
          if (res.status === 200) {
            this.modalidadesRegistradas = res.data;
          }
          this.tableLoadingModalidades = false;
        })
        .catch(() => {
          this.modalidadesRegistradas = [];
          this.tableLoadingModalidades = false;
        });
    },

    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnRegistroLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cooperantes\\";

      if (
        (this.actualizaImagen == true && this.accion === 2) ||
        this.accion === 1
      ) {
        await this.$store
          .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
          .then(res => {
            //console.logres
            if (res.status === 200) {
              this.archivoCargado = true;

              //Agregar el path en función del tipo de archivo que se cargó

              this.datosItem.pathMaximaAutoridad = res.pathArchivo;

              //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
              this.registrarItem();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.mensaje
              );
              this.archivoCargado = false;
              this.btnRegistroLoading = false;
            }
            //this.btnRegistroLoading=false;
          })
          .catch(error => {
            this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
            this.btnRegistroLoading = false;
          });
      } else {
        this.registrarItem();
      }
    },

    //Registrar un socio implementador
    async registrarItem() {
      this.btnRegistroLoading = true;

      //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
      /* this.switchItemEstado
                  ? (this.datosItem.estadosId = 1)
                  : (this.datosItem.estadosId = 2); */

      this.datosItem.estadosId = 1;
      this.datosItem.tiposFuenteCooperanteId = this.tipoFuenteCooperanteSeleccionada.id;

      if (this.tipoFuenteCooperanteSeleccionada.id === 1) {
        this.datosItem.fuentesBilateralesId = this.fuenteBilateralSeleccionada.id;
        this.datosItem.fuentesMultilateralesId = null;
        this.datosItem.agenciaMultilateralId = null;
        if (this.agenciaSeleccionada.id && this.agenciaSeleccionada.id > 0) {
          this.datosItem.agenciasId = this.agenciaSeleccionada.id;
        } else {
          this.datosItem.agenciasId = null;
        }
      } else {
        this.datosItem.fuentesBilateralesId = null;
        this.datosItem.agenciasId = null;
        this.datosItem.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;

        if (
          this.agenciaMultilateralSeleccionada.id &&
          this.agenciaMultilateralSeleccionada.id > 0
        ) {
          this.datosItem.agenciaMultilateralId = this.agenciaMultilateralSeleccionada.id;
        } else {
          this.datosItem.agenciaMultilateralId = null;
        }
      }

      if (this.datosItem.estrategiaCNSCId === 0) {
        this.datosItem.estrategiaCNSCId = null;
      }

      let dispatch = REGISTRAR_COOPERANTE_CNSC;

      //?Si accion es 1 se realiza el registro si es 2 la actualización
      if (this.accion === 2) {
        dispatch = ACTUALIZAR_COOPERANTE_CNSC;
      }

      await this.$store
        .dispatch(dispatch, this.datosItem)
        .then(res => {
          if (res.status === 200) {
            //this.nombreInstitucion = this.datosItem.nombreInstitucion;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenercooperantes();
            this.dialogRegistro = false;

            this.resetForm();
            this.dialogRegistro = false;
            //this.tab = "tab-2";
            //this.accion = 2;
            this.tabContactosDisabled = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnRegistroLoading = false;
        });
    },

    //!Obtener datos de un item especifico
    async obtenerDatosItem(Id) {
      this.cooperanteId = Id;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";

      this.btnRegistroText = `Actualizar`;
      //this.datosItem={};
      await this.$store
        .dispatch(OBTENER_COOPERANTE_CNSC, Id)
        .then(res => {
          //console.logres;
          if (res.status === 200) {
            this.datosItem = res.data;

            if (this.datosItem.pathMaximaAutoridad) {
              this.imageUrl = this.datosItem.pathMaximaAutoridad;
            } else {
              this.imageUrl = null;
            }
            this.datosSector.cooperantesCNSCId = Id;
            this.datosModalidad.cooperantesCNSCId = Id;
            this.datosItem.estadosId === 1;

            /* this.tipoFuenteCooperanteSeleccionada.id = this.datosItem.tiposFuenteCooperanteId;
                      this.tipoFuenteCooperanteSeleccionada.nombreTipoFuente = this.datosItem.nombreTipoFuente; */

            this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(
              item => item.id === this.datosItem.tiposFuenteCooperanteId
            );

            if (this.datosItem.tiposFuenteCooperanteId === 1) {
              /* this.fuenteBilateralSeleccionada.id = this.datosItem.fuentesBilateralesId;
                        this.fuenteBilateralSeleccionada.pais = this.datosItem.nombrePais; */
              this.fuenteBilateralSeleccionada = this.fuentesBilaterales.find(
                item => item.id === this.datosItem.fuentesBilateralesId
              );
              this.datosItem.fuentesMultilateralesId = null;
              this.fuenteMultilateralSeleccionada = {};

              //this.nombreCooperante = this.datosItem.nombrePais;

              if (this.datosItem.agenciasId) {
                this.obtenerAgenciasPorFuenteBilateral().then(() => {
                  /* this.agenciaSeleccionada.id = this.datosItem.agenciasId; 
                            this.agenciaSeleccionada.nombreAgencia = this.datosItem.nombreAgencia; */
                  this.agenciaSeleccionada = this.agencias.find(
                    item => item.id === this.datosItem.agenciasId
                  );
                });
              }
            } else {
              this.datosItem.fuentesBilateralesId = null;
              this.datosItem.agenciasId = null;
              this.agenciaSeleccionada = {};
              /* this.fuenteMultilateralSeleccionada.id = this.datosItem.fuentesMultilateralesId; */
              this.fuenteMultilateralSeleccionada = this.fuentesMultilateral.find(
                item => item.id === this.datosItem.fuentesMultilateralesId
              );

              //this.nombreCooperante = this.datosItem.nombreFuenteMultilateral;
              if (this.datosItem.agenciaMultilateralId) {
                this.obtenerAgenciasMultilaterales(
                  this.datosItem.fuentesMultilateralesId
                ).then(() => {
                  this.agenciaMultilateralSeleccionada = this.agenciasMultilaterales.find(
                    item => item.id === this.datosItem.agenciaMultilateralId
                  );
                });

                // this.nombreCooperante = this.datosItem.nombreAgencia + " - " + this.datosItem.nombrePais;
              }
            }

            this.nombreCooperante =
              this.datosItem.agencia + " - " + this.datosItem.fuente;

            this.obtenerSectoresPrioritariosBeneficiados(this.cooperanteId);
            this.obtenerModalidadesCooperacionCooperantes(this.cooperanteId);

            /* ? (this.switchItemEstado = true)
                      : (this.switchItemEstado = false); */
            this.accion = 2;
            this.dialogRegistro = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          //console.logerror
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
          );
        });
    },

    async registrarSector() {
      this.btnRegistroSectoresLoading = true;
      await this.$store
        .dispatch(REGISTRAR_SECTOR_COOPERANTE, { datos: this.datosSector })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.datosSector.sectoresId = 0;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerSectoresPrioritariosBeneficiados(this.cooperanteId);
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroSectoresLoading = false;
        })
        .catch(error => {
          this.btnRegistroSectoresLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async eliminarSector(id) {
      this.btnEliminarSectorDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_SECTOR_COOPERANTE, { id: id })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.obtenerSectoresPrioritariosBeneficiados(this.cooperanteId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarSectorDisabled = false;
        })
        .catch(error => {
          this.btnEliminarSectorDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async registrarModalidad() {
      this.btnRegistroModalidadesLoading = true;
      await this.$store
        .dispatch(
          REGISTRAR_MODALIDAD_COOPERACION_COOPERANTE,
          this.datosModalidad
        )
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerModalidadesCooperacionCooperantes(this.cooperanteId);
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroModalidadesLoading = false;
        })
        .catch(error => {
          this.btnRegistroModalidadesLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async eliminarModalidad(id) {
      this.btnEliminarModalidadDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_MODALIDAD_COOPERACION_COOPERANTE, { id: id })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.obtenerModalidadesCooperacionCooperantes(this.cooperanteId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarModalidadDisabled = false;
        })
        .catch(error => {
          this.btnEliminarModalidadDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    inhabilitarItem(id, agencia, fuente) {
      this.cooperanteId = id;
      this.tipoConfirmacion = 2;
      this.nombreCooperante = agencia + `, ` + fuente;
      this.dialogConfirmacion = true;
    },

    habilitarItem(id, agencia, fuente) {
      this.cooperanteId = id;
      this.tipoConfirmacion = 1;
      this.nombreCooperante = agencia + `, ` + fuente;
      this.dialogConfirmacion = true;
    },

    async actualizarEstadoCooperante() {
      this.btnConfirmacionLoading = true;

      this.btnConfirmacionLoading = false;
    }
  },

  created() {
    this.obtenercooperantes();
    this.obtenerTiposFuenteCooperante();
    this.obtenerFuentesBilaterales();
    this.obtenerFuentesMultilateral();
    this.obtenerSectoresBeneficiados();
    this.obtenerModalidadesCooperacion();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Administracion" },
      { title: "Cooperantes" }
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Id",
          align: "d-none",
          sortable: false,
          value: "id"
        },
        {
          text: "Fuente cooperante",
          align: "start",
          sortable: true,
          value: "fuente"
        },
        {
          text: "Agencia",
          align: "start",
          sortable: true,
          value: "agencia"
        },
        {
          text: "Tipo fuente cooperante",
          align: "start",
          sortable: true,
          value: "nombreTipoFuente"
        },
        {
          text: "Máxima autoridad",
          align: "start",
          sortable: true,
          value: "maximaAutoridad"
        },
        /*  {
                  text: "Máxima autoridad",
                  align: "start",
                  sortable: true,
                  value: "institucion"
              }, */
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersSectores() {
      return [
        /*  {
                  text: "Id",
                  align: "end",
                  sortable: false,
                  value: "id"
              }, */
        {
          text: "Sector",
          align: "start",
          sortable: false,
          value: "sector"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersModalidades() {
      return [
        /* {
                  text: "Id",
                  align: "end",
                  sortable: false,
                  value: "id"
              }, */
        {
          text: "Modalidad",
          align: "start",
          sortable: false,
          value: "modalidad"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
<style lang="scss">
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
